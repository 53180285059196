import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { BI_ACCESS, COMPANY_TYPE, DEPARTMENT } from "redux/constants/User";

export const AppViews = () => {
  const companyType = localStorage.getItem(COMPANY_TYPE);
  const department = localStorage.getItem(DEPARTMENT);
  const bi_access = JSON.parse(localStorage.getItem(BI_ACCESS));

  return (
    <Suspense fallback={<Loading cover='content' />}>
      <Switch>
        <Route
          exact
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/asset/:stage/:pk/details`}
          component={lazy(() => import(`./assetDetails`))}
        />
        <Route
          exact
          path={`${APP_PREFIX_PATH}/assets/:stage/overview`}
          component={lazy(() => import(`./assetsOverview`))}
        />
        {(companyType === "Brewery" ||
          companyType === "Beverage" ||
          companyType === "Winery") && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/insights`}
            component={lazy(() => import(`./insights`))}
          />
        )}
        {(companyType === "Brewery" ||
          companyType === "Beverage" ||
          companyType === "Winery") && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/events`}
            component={lazy(() => import(`./events`))}
          />
        )}
        {(companyType === "Brewery" || companyType === "Beverage") && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/recipes`}
            component={lazy(() => import(`./recipes`))}
          />
        )}
        {companyType !== "Generic" && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/batches/`}
            component={lazy(() => import(`./batchManagement`))}
          />
        )}
        {companyType === "Brewery" && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/cip/`}
            component={lazy(() => import(`./cip`))}
          />
        )}

        {companyType === "Brewery" && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/data-entry/`}
            component={lazy(() => import(`./dataEntry`))}
          />
        )}
        {(companyType === "Brewery" || companyType === "Beverage") && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/inventory/`}
            component={lazy(() => import(`./inventory`))}
          />
        )}

        {(companyType === "Brewery" || companyType === "Beverage") &&
          department !== "Shift Monitoring" &&
          bi_access && (
            <Route
              exact
              path={`${APP_PREFIX_PATH}/productivity`}
              component={lazy(() => import(`./productivity`))}
            />
          )}

        {companyType === "Brewery" && bi_access && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/recipe-analytics`}
            component={lazy(() => import(`./recipeAnalytics`))}
          />
        )}

        {companyType === "Brewery" && bi_access && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/batch-comparison`}
            component={lazy(() => import(`./batchComparison`))}
          />
        )}

        <Route
          exact
          path={`${APP_PREFIX_PATH}/data-comparison`}
          component={lazy(() => import(`./dataComparison`))}
        />

        {companyType === "Generic" && (
          <Route
            exact
            path={`${APP_PREFIX_PATH}/reports`}
            component={lazy(() => import(`./reports`))}
          />
        )}

        <Route
          exact
          path={`${APP_PREFIX_PATH}/batch/:stage/:pk/details`}
          component={lazy(() => import(`./batchDetails`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./settings`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/thresholds`}
          component={lazy(() => import(`./thresholds`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/user-list`}
          component={lazy(() => import(`./userList`))}
        />

        <Route component={lazy(() => import(`./notFound`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
