import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Websocket from "./Websocket";

let IS_LIVE_UPDATE_ENABLED;
if ("LIVE_UPDATE_ENABLED" in process.env) {
  IS_LIVE_UPDATE_ENABLED = process.env.LIVE_UPDATE_ENABLED;
} else {
  IS_LIVE_UPDATE_ENABLED = "true";
}

export default function* rootSaga() {
  IS_LIVE_UPDATE_ENABLED === "true"
    ? yield all([Auth(), Websocket()])
    : yield all([Auth()]);
}
